import React, { useEffect, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import {
  useDrawNamesFamilyFamilyIdDrawPutMutation,
  useMyFamilyMembersFamilyMeMembersGetQuery,
} from "../../store/apiSlice";
import { Alert, CircularProgress, List, ListItemButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { NotFoundError } from "../../models/errorModels";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { IMember, INameDrawMember } from "./interfaces";
import NameDrawSendEmail from "./NameDrawSendEmail";
import { mapApiMember } from "./functions";

export interface ICreateNameDrawProps {
  familyId?: string;
}
export default function CreateNameDraw({ familyId }: ICreateNameDrawProps) {
  const {
    data: myFamilyData,
    isLoading: isLoadingMyFamily,
    error: myFamilyError,
  } = useMyFamilyMembersFamilyMeMembersGetQuery();

  const [
    drawTrigger,
    {
      isLoading: isSubmitting,
      error: createDrawError,
      data: createDrawResponse,
    },
  ] = useDrawNamesFamilyFamilyIdDrawPutMutation();

  const [selectedMembers, setSelectedMembers] = useState<IMember[]>([]);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [validationError, setValidationError] = useState(false);

  const routeParams = useParams();

  const famId = familyId ? familyId : routeParams.familyId ?? "";

  if (!famId) throw new NotFoundError();

  const navigate = useNavigate();

  useEffect(() => {
    if (isDone || isCancelled) {
      navigate("/myfamily");
    }
  }, [isDone, isCancelled]);

  const mappedMembers: INameDrawMember[] = useMemo(() => {
    if (selectedMembers.length > 0 && isSubmitted) {
      return selectedMembers.map(
        (m) =>
          ({
            familyId: famId,
            memberId: m.id,
            memberName: m.name,
          }) as INameDrawMember,
      );
    }
    return [];
  }, [selectedMembers, isSubmitted]);

  const isSelected = (memberId: string | null | undefined) => {
    if (!memberId) return false;
    return selectedMembers.some((m) => m.id === memberId);
  };

  const handleSelectedMember = (member: IMember | null | undefined) => {
    if (!member) return;

    if (isSelected(member.id)) {
      setSelectedMembers(selectedMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const onCancelClicked = () => {
    setIsCancelled(true);
  };

  const onSubmitClicked = () => {
    if (selectedMembers && selectedMembers.length > 1) {
      setValidationError(false);
      drawTrigger({
        familyId: famId,
        createNameDrawRequest: {
          participating_member_ids: selectedMembers.map((m) => m.id),
        },
      });
      setIsSubmitted(true);
    } else {
      setValidationError(true);
    }
  };

  const onDoneClick = () => {
    setIsDone(true);
  };

  return (
    <>
      {validationError && (
        <Alert severity={"error"}>
          Please select at least 2 members to participate.
        </Alert>
      )}
      {myFamilyError && (
        <Alert severity={"error"}>
          Unable to load name draw info. Please refresh your browser.
        </Alert>
      )}
      {createDrawError && (
        <Alert severity={"error"}>
          Unable to create a name draw. Please try again.
        </Alert>
      )}
      {!isLoadingMyFamily && myFamilyData?.members && !isSubmitted && (
        <>
          <Box>
            <Typography>Select all members that will participate:</Typography>
          </Box>
          <Box>
            <List aria-label="name draw members">
              {myFamilyData.members.map((m) => (
                <ListItemButton
                  key={m.member_id}
                  selected={isSelected(m.member_id)}
                  onClick={() => handleSelectedMember(mapApiMember(m))}
                >
                  {isSelected(m.member_id) && (
                    <FontAwesomeIcon
                      style={{ marginRight: ".5rem" }}
                      icon={faSquareCheck}
                    />
                  )}
                  {m.member_name}
                </ListItemButton>
              ))}
            </List>
            <Box>
              <Button
                sx={{ mr: "1rem" }}
                disabled={isSubmitting}
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => onSubmitClicked()}
              >
                Submit {isSubmitting && <CircularProgress size={"1rem"} />}
              </Button>
              <Button
                disabled={isSubmitting}
                variant="outlined"
                color="secondary"
                type="button"
                onClick={() => onCancelClicked()}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </>
      )}
      {createDrawResponse && (
        <>
          <Alert sx={{ mb: "1rem" }} severity={"info"}>
            The draw is complete! Send an email to let each member know who they
            have drawn.
          </Alert>

          <Stack sx={{ mb: "1rem" }} spacing={2}>
            {mappedMembers.map((member) => (
              <NameDrawSendEmail key={member.memberId} member={member} />
            ))}
          </Stack>

          <Box>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => onDoneClick()}
            >
              Done
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

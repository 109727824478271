import React, { useEffect, useState } from "react";
import { useCreateInvitationInvitationPostMutation } from "../../store/apiSlice";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Alert, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export interface ICreateInviteProps {
  familyId?: string;
}

export default function CreateInvite({ familyId }: ICreateInviteProps) {
  const [
    createInviteTrigger,
    { isLoading: isSubmitting, error: createError, data: createInviteResponse },
  ] = useCreateInvitationInvitationPostMutation();
  const [memberName, setMemberName] = useState<string>();
  const [memberNameError, setMemberNameError] = useState(false);
  const [memberEmailError, setMemberEmailError] = useState(false);
  const [memberEmail, setMemberEmail] = useState<string>();
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!familyId && (!state || !state.familyId)) {
    throw new Error("Missing familyId props and no route state was given.");
  }

  familyId = state.familyId;

  useEffect(() => {
    if (createInviteResponse && !createError) {
      navigate("/myfamily");
    }
  }, [createInviteResponse]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let hasError = false;
    if (!memberName) {
      setMemberNameError(true);
      hasError = true;
    } else {
      setMemberNameError(false);
    }

    if (!memberEmail) {
      setMemberEmailError(true);
      hasError = true;
    } else {
      setMemberEmailError(false);
    }

    if (!hasError) {
      createInviteTrigger({
        inviteRequest: {
          family_id: familyId ?? "",
          name: memberName ?? "",
          email: memberEmail ?? "",
        },
      });
    }
  };

  const getErrorDetail = (
    error: FetchBaseQueryError | SerializedError,
  ): string | undefined => {
    const queryError = error as FetchBaseQueryError;

    if (queryError) {
      if (queryError.status === 409)
        return "Invite has already been sent to this member.";
    }

    return "There was a problem submitting the invite. Please try again.";
  };

  const onCancel = () => {
    navigate("/myfamily");
  };
  return (
    <>
      <Box sx={{ mb: "1rem" }}>
        {createError && (
          <Alert severity={"error"}>
            <Typography>{getErrorDetail(createError)}</Typography>
          </Alert>
        )}
      </Box>
      <form
        aria-disabled={isSubmitting}
        autoComplete="off"
        onSubmit={(e) => handleSubmit(e)}
      >
        <TextField
          disabled={isSubmitting}
          id="name"
          label="Invitee's name"
          onChange={(e) => setMemberName(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="text"
          sx={{ mb: 3 }}
          fullWidth
          value={memberName}
          error={memberNameError}
          autoFocus={true}
        />
        <TextField
          disabled={isSubmitting}
          id="email"
          label="Invitee's email"
          onChange={(e) => setMemberEmail(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="email"
          sx={{ mb: 3 }}
          fullWidth
          value={memberEmail}
          error={memberEmailError}
        />
        <Divider sx={{ mb: "1em" }} />
        <Box>
          <Button
            sx={{ mr: "1rem" }}
            disabled={isSubmitting}
            variant="outlined"
            color="primary"
            type="submit"
          >
            Submit
            {isSubmitting && <CircularProgress size={"1rem"} />}
          </Button>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            color="secondary"
            type="button"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </>
  );
}

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

export interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandCard = (props: ExpandMoreProps) => {
  const { expand, children, ...other } = props;

  const text = expand ? "Hide" : "Details";

  return (
    <>
      {children && <IconButton {...other} />}
      {!children && (
        <IconButton sx={{ ml: "auto" }} {...other}>
          <Typography>{text}</Typography>
          <ExpandMoreIcon />
        </IconButton>
      )}
    </>
  );
};

export default ExpandCard;

import React from "react";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";
import { IMemberWish } from "./interfaces";
import MemberWishCard from "./MemberWishCard";
import SentimentVeryDissatisfiedSharpIcon from "@mui/icons-material/SentimentVeryDissatisfiedSharp";

export interface IMemberWishListProps {
  wishes?: IMemberWish[];
  currentUserId: string;
}
export default function MemberWishList({
  wishes,
  currentUserId,
}: IMemberWishListProps) {
  return (
    <>
      {(!wishes || wishes.length === 0) && (
        <Alert icon={<SentimentVeryDissatisfiedSharpIcon />} severity="info">
          There are no wyshes yet...
        </Alert>
      )}
      {wishes && wishes.length > 0 && (
        <>
          {wishes.map((wish) => (
            <Box sx={{ mb: "1rem" }} key={wish.id}>
              <MemberWishCard data={wish} currentUserId={currentUserId} />
            </Box>
          ))}
        </>
      )}
    </>
  );
}

export const useStorage = (
  keyName: string,
  defaultValue: object | undefined | null,
): [object | null | undefined, (o: object | null | undefined) => void]  => {
  const setValue = (newValue: object | null | undefined): void => {
    try {
        if(!newValue)
            window.sessionStorage.removeItem(keyName);
        else
            window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.log(err);
    }
  };

 try {
    const value = window.sessionStorage.getItem(keyName);
    if (value) {
      return [JSON.parse(value), setValue];
    } else {
      window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
      return [defaultValue, setValue];
    }
  } catch (err) {
    return [defaultValue, setValue];
  }
};

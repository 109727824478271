import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  UpdateWishRequest,
  useGetMemberWishWishWishIdGetQuery,
  useUpdateMemberWishMemberMemberIdWishWishIdPutMutation,
} from "../../store/apiSlice";
import { MemberWishForm } from "./MemberWishForm";
import { IMemberWish } from "./interfaces";
import { useAuth } from "../../hooks/useAuth";
import { Alert, CircularProgress } from "@mui/material";
import { mapApiWishToMemberWish } from "./functions";

export default function () {
  const { authContext } = useAuth();

  const params = useParams();
  const wishId = params?.wishId ?? "";

  const {
    data: wishData,
    isLoading: isFetching,
    error: fetchError,
  } = useGetMemberWishWishWishIdGetQuery({ wishId });

  const [
    updateWishTrigger,
    { isLoading: isUpdating, error: updateError, isSuccess: wasUpdateSuccess },
  ] = useUpdateMemberWishMemberMemberIdWishWishIdPutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (wasUpdateSuccess) {
      navigate("/me");
    }
  }, [wasUpdateSuccess]);

  const onCancel = () => {
    navigate("/me");
  };
  const onSubmit = (updatedData: IMemberWish) => {
    const targetWish = {
      memberId: authContext.userId ?? "",
      wishId,
      updateWishRequest: {
        title: updatedData.title ?? "",
        description: updatedData.description ?? "",
        example_url: updatedData.exampleUrl ?? null,
        properties: updatedData.attributes ?? null,
      } as UpdateWishRequest,
    };

    updateWishTrigger(targetWish);
  };

  return (
    <>
      {isFetching && <CircularProgress />}
      {fetchError && (
        <Alert severity="error">
          There was an error fetching information about this wish. Please try
          again.
        </Alert>
      )}
      {updateError && (
        <Alert severity="error">
          There was an error updating this wish. Please try again.
        </Alert>
      )}
      {!isFetching && (
        <MemberWishForm
          data={wishData ? mapApiWishToMemberWish(wishData) : undefined}
          submitButtonText="Submit"
          isSubmitting={isUpdating}
          onSubmit={(data) => onSubmit(data)}
          onCancel={() => onCancel()}
        />
      )}
    </>
  );
}

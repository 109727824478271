import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../store";
import { IAuthContext } from "../../hooks/useAuth";
import { IMember } from "../family/interfaces";
import Typography from "@mui/material/Typography";

export interface IMemberProfileCardProps {
  member: IMember;
}

export function MemberProfileCard({ member }: IMemberProfileCardProps) {
  const navigate = useNavigate();

  const authContext = useSelector<IStore, IAuthContext | undefined>(
    (store) => store.auth,
  );

  const isForCurrentUser =
    member.id?.toLowerCase() === authContext?.userId?.toLowerCase();

  const onClick = () => {
    navigate("/addWish");
  };
  return (
    <>
      <Card variant={"outlined"}>
        <CardHeader
          avatar={
            <Avatar
              src={member.picUrl}
              aria-label="member-profile-pic"
            ></Avatar>
          }
          disableTypography={true}
          title={<Typography>{member.name}</Typography>}
        />
        <CardContent>
          {isForCurrentUser && (
            <Button
              variant="outlined"
              sx={{ borderRadius: "1", mt: ".5rem" }}
              onClick={() => onClick()}
            >
              Add Wysh
            </Button>
          )}
        </CardContent>
      </Card>
    </>
  );
}

import React from "react";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import { IMember } from "./interfaces";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

interface IFamilyMemberProps {
  member: IMember;
}
export default function FamilyMember({ member }: IFamilyMemberProps) {
  const navigate = useNavigate();
  const navigateToMember = () => {
    navigate(`/member/${member.id}`);
  };
  return (
    <Card
      variant={"outlined"}
      sx={{ cursor: "pointer" }}
      title={"View wyshes"}
      onClick={() => navigateToMember()}
    >
      <CardHeader
        avatar={
          <Avatar
            src={member.picUrl ?? undefined}
            aria-label="member-profile-pic"
          ></Avatar>
        }
        disableTypography={true}
        title={<Typography>{member.name}</Typography>}
      />
    </Card>
  );
}

import React from "react";
import { IMemberWishGranter } from "./interfaces";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { OnGrantWish, OnUngrantWish } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";

export interface IMemberWishGrantManagerProps {
  onGrantWish: OnGrantWish;
  onUngrantWish: OnUngrantWish;
  isSubmitting: boolean;
  wishOwnerId?: string;
  currentUserId?: string;
  granter?: IMemberWishGranter;
  hadGrantError: boolean;
  hadUngrantError: boolean;
}

interface IGranterSummaryProps {
  granterName: string;
  onUngrantWish: OnUngrantWish;
  isSubmitting: boolean;
}
function GranterSummary({
  granterName,
  onUngrantWish,
  isSubmitting,
}: IGranterSummaryProps) {
  return (
    <>
      <Box>
        <Typography>
          Granted By: {granterName}{" "}
          <FontAwesomeIcon
            style={{ marginRight: ".5rem", cursor: "pointer" }}
            title={"Ungrant this wysh"}
            onClick={() => onUngrantWish()}
            color={"red"}
            icon={faCircleMinus}
          />
          {isSubmitting && <CircularProgress size={"1rem"} />}
        </Typography>
      </Box>
    </>
  );
}
export function MemberWishGrantManager({
  currentUserId,
  wishOwnerId,
  granter,
  isSubmitting,
  onGrantWish,
  hadGrantError,
  onUngrantWish,
  hadUngrantError,
}: IMemberWishGrantManagerProps) {
  //the current user owns this wish, so we don't wanna showing granting info
  const hide = wishOwnerId?.toLowerCase() === currentUserId?.toLowerCase();

  if (hide) {
    return <></>;
  }

  return (
    <>
      {hadGrantError && (
        <Alert severity="error">
          There was an error granting this wish. Please try again.
        </Alert>
      )}
      {hadUngrantError && (
        <Alert severity="error">
          There was an error ungranting this wish. Please try again.
        </Alert>
      )}
      {granter && (
        <GranterSummary
          granterName={granter.granterName}
          onUngrantWish={onUngrantWish}
          isSubmitting={isSubmitting}
        />
      )}
      {!granter && (
        <>
          <Box>
            <Button
              title={"Grant this wysh"}
              disabled={isSubmitting}
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => onGrantWish()}
            >
              <Typography sx={{ mr: ".5rem" }}>Grant Wysh</Typography>
              {isSubmitting && <CircularProgress size={"1rem"} />}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

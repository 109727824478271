import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { IStore, useAppDispatch } from "../store";
import { clearAuthContext, saveAuthContext } from "../store/authSlice";
import { googleLogout } from "@react-oauth/google";
import { useSelector } from "react-redux";

export interface IAuthContext {
  idToken?: string;
  userId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  picUrl?: string;
  email?: string;
  exp?: number;
}

type LoginCallback = (authContext: IAuthContext) => void;
type Logout = () => void;

export interface IAuth {
  authContext: IAuthContext;
  onLogin: LoginCallback;
  logout: Logout;
}
const AuthContext = createContext<IAuth>({} as IAuth);

export const AuthProvider = (props: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const authContext = useSelector((state: IStore) => state.auth);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const clearData = () => {
    dispatch(clearAuthContext());
  };

  const onLogin = async (authContext: IAuthContext) => {
    dispatch(saveAuthContext(authContext));
  };

  const logout = () => {
    clearData();
    googleLogout();
  };

  const value = useMemo(
    () => ({
      authContext,
      onLogin,
      logout,
    }),
    [authContext],
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

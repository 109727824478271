import { FamilyMember } from "../../store/apiSlice";
import { IMember, INameDrawPair } from "./interfaces";

export function mapApiMember(familyMember: FamilyMember): IMember {
  return {
    id: familyMember.member_id,
    name: familyMember.member_name,
    picUrl: familyMember.pic_url,
  } as IMember;
}

export function transformNameDrawToMemberNames(
  nameDraw: [string, string][],
  members: IMember[],
): INameDrawPair[] {
  const transformedMembers: (INameDrawPair | null)[] = nameDraw.map(
    (idTuple): INameDrawPair | null => {
      const [firstId, secondId] = idTuple;
      const firstName = members.find((m) => m.id === firstId)?.name;
      const secondName = members.find((m) => m.id === secondId)?.name;
      if (!firstName) return null;

      if (!secondName) return null;

      return {
        drawingMemberId: firstId,
        drawingMemberName: firstName,
        memberDrawnId: secondId,
        memberDrawnName: secondName,
      } as INameDrawPair;
    },
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return transformedMembers.filter((m) => m != null);
}

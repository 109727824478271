

const LocalStorageService = {
    getItem: (key: string) => localStorage.getItem(key),
    setItem:(key:string, value: string) =>localStorage.setItem(key, value),
    removeItem:(key:string) => localStorage.removeItem(key)
};

export default LocalStorageService;

export const LocalStorageKeys = {
  UserPreferences_Mode: "userPreferences.mode",
};
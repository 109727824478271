import React from "react";
import Box from "@mui/material/Box";
import { IErrorResponse } from "../models/errorModels";
export function ErrorDetail({
  message,
  description,
  component,
}: IErrorResponse) {
  return (
    <>
        <h1>{message}</h1>
        {description && <h2>{description}</h2>}
        <Box>{component}</Box>
    </>
  );
}

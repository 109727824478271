import React from "react";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export type OnAfterClick = () => void;

export interface IFamilyButtonProps {
  onAfterClick?: OnAfterClick;
}
export default function FamilyButton({ onAfterClick }: IFamilyButtonProps) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/myfamily");
    if (onAfterClick) onAfterClick();
  };
  return (
    <>
      <Button
        sx={{ width: "100%", justifyContent: "flex-start" }}
        variant="text"
        onClick={() => onClick()}
      >
        <Typography textAlign={"left"}>
          <FontAwesomeIcon
            icon={faUsers}
            color={"#736aff"}
            style={{ marginRight: ".5rem" }}
          />
          Family
        </Typography>
      </Button>
    </>
  );
}

import React from "react";
import { useLocation, useRouteError } from "react-router-dom";
import SignIn from "../../components/SignIn";
import { errorTypes, IErrorResponse } from "../../models/errorModels";
import { ErrorDetail } from "../../components/ErrorDetail";
import { AcceptInviteButton } from "../../components/AcceptInviteButton";

const defaultErrorMessage =
  "Oops! There was an unexpected error. Please try again.";

function getDefaultErrorResponse(): IErrorResponse {
  return {
    message: defaultErrorMessage,
  } as IErrorResponse;
}

function getErrorResponseFromApiResult(err: object | null): IErrorResponse {
  if (!err) {
    return getDefaultErrorResponse();
  }

  if (err && "status" in err) {
    if (err.status === 403) {
      return {
        message:
          "This site is by invitation only and requires an access code to sign up.",
        component: AcceptInviteButton(),
      } as IErrorResponse;
    }
  }

  if ("data" in err) {
    const data = err["data"] as object;
    if ("detail" in data) {
      return {
        message: data["detail"],
      } as IErrorResponse;
    }
  }

  return getDefaultErrorResponse();
}

function getErrorResponse(err: object): IErrorResponse {
  if (!err) {
    return getDefaultErrorResponse();
  }

  const name = "name" in err ? err["name"] : null;
  const message = "message" in err ? err["message"] : defaultErrorMessage;

  if (err && "status" in err) {
    if (err.status === 403) {
      return {
        message:
          "This site is by invitation only and requires an access code to sign up.",
        component: AcceptInviteButton(),
      } as IErrorResponse;
    }
  }

  if (name === errorTypes.invalidToken) {
    return {
      message: message,
      component: <SignIn disableRedirect={true} />,
    } as IErrorResponse;
  }

  if (err) {
    return {
      message: message,
      component: <></>,
    } as IErrorResponse;
  }

  return getDefaultErrorResponse();
}

export default function ErrorPage() {
  const err = useRouteError();
  const { state } = useLocation();

  let response = getDefaultErrorResponse();

  if (err) {
    console.log("UNHANDLED ERROR", err);
    response = getErrorResponse(err);
  }

  if (state) {
    console.log("STATE ERROR", state);
    response = getErrorResponseFromApiResult(state);
  }

  return <ErrorDetail {...response} />;
}

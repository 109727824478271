import { configureStore } from "@reduxjs/toolkit";
import authSliceReducer from "./authSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "./apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { IAuthContext } from "../hooks/useAuth";
import { getEndpointTagConfiguration } from "./apiSlice/baseApi";

export interface IStore {
  auth: IAuthContext;
}

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    api: apiSlice.enhanceEndpoints(getEndpointTagConfiguration()).reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch<AppDispatch>;

import React from "react";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";
import SignIn from "../../components/SignIn";
import { validToken } from "../login/functions";

export default function Home() {
  const { authContext } = useAuth();

  const isValidToken = validToken(authContext);

  return (
    <>
      <Typography color="text.primary" variant="h5">
        Welcome to OurWyshlist...
      </Typography>
      <Typography sx={{ mb: "1rem" }} color="text.primary" variant="subtitle1">
        Where your wyshes come true!
      </Typography>
      {!isValidToken && <SignIn />}
    </>
  );
}

import { IAuthContext } from "../../hooks/useAuth";

export function validToken(authContext?: IAuthContext) {
  if (!authContext) return false;

  try {
    // noinspection RedundantIfStatementJS
    if (authContext?.exp && authContext.exp * 1000 > Date.now()) {
      return true;
    }
  } catch (err) {
    console.log("Error decoding token.", err);
    return false;
  }

  return false;
}

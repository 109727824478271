import React, {useEffect} from "react";
import { Box, PaletteMode } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Masthead from "../components/Masthead";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "../store";
import { AuthProvider } from "../hooks/useAuth";
import createDefaultTheme from "../theme";
import LocalStorageService, {LocalStorageKeys} from "../services/LocalStorageService";
export default function Layout() {
  const defaultMode = "light" as PaletteMode;
  const initialMode = (LocalStorageService.getItem(LocalStorageKeys.UserPreferences_Mode) as PaletteMode ?? defaultMode);

  const [mode, setMode] =
      React.useState<PaletteMode>(initialMode);
  const defaultTheme = createDefaultTheme(mode);

  defaultTheme.typography.h3 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [defaultTheme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  };

  useEffect(() => {
    LocalStorageService.setItem(LocalStorageKeys.UserPreferences_Mode, mode)
  }, [mode]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const clientId = process.env.REACT_APP_CLIENT_ID;

  if(!clientId)
    throw new Error("No client id has been configured.");

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <React.StrictMode>
          <Provider store={store}>
            <AuthProvider>
              <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Masthead mode={mode} toggleColorMode={toggleColorMode} />
                <Container id="rootContainer" sx={{ py: { xs: 12, sm: 16 } }}>
                  <Box
                    sx={{
                      marginLeft: { xs: "5%", md: "15%" },
                      marginRight: { xs: "5%", md: "15%" },
                    }}
                  >
                    <Outlet />
                  </Box>
                </Container>
                <Footer />
              </ThemeProvider>
            </AuthProvider>
          </Provider>
        </React.StrictMode>
      </GoogleOAuthProvider>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validToken } from "../routes/login/functions";
import { IAuthContext, useAuth } from "../hooks/useAuth";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { InvalidTokenError, jwtDecode, JwtPayload } from "jwt-decode";

export type OnAfterSignInClick = () => void;

interface ISignInProps {
  style?: object | undefined;
  disableRedirect?: boolean;
  showLabel?: boolean;
  onAfterClick?: OnAfterSignInClick;
}

export interface GoogleJwtPayload extends JwtPayload {
  email?: string;
  name?: string;
  given_name?: string;
  family_name?: string;
  picture?: string;
}
export default function SignIn({
  disableRedirect = false,
  showLabel = false,
  onAfterClick,
}: ISignInProps) {
  const navigate = useNavigate();
  const { onLogin, authContext, logout } = useAuth();
  const [hasValidToken, setHasValidToken] = useState(false);
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [searchParams] = useSearchParams();
  const navigateToLogin = () => {
    const redirectUri = searchParams.get("redirectTo");

    if (!disableRedirect && redirectUri) {
      navigate(redirectUri);
    } else if (!disableRedirect) {
      navigate("/");
    }
  };

  const onLogout = () => {
    if (onAfterClick) onAfterClick();
    logout();
  };

  const onSuccess = (response: CredentialResponse) => {
    const credential = response?.credential;

    if (!credential) {
      throw new InvalidTokenError();
    }

    const token = jwtDecode<GoogleJwtPayload>(credential);

    onLogin({
      idToken: credential,
      email: token.email,
      name: token.name,
      firstName: token.given_name,
      lastName: token.family_name,
      picUrl: token.picture,
      userId: token.sub,
      exp: token.exp,
    } as IAuthContext);

    if (onAfterClick) onAfterClick();

    setLoginSuccessful(true);
  };

  useEffect(() => {
    setHasValidToken(validToken(authContext));
  }, [authContext]);

  useEffect(() => {
    if (loginSuccessful) navigateToLogin();
  }, [loginSuccessful]);

  const onError = () => {
    throw new InvalidTokenError();
  };

  return (
    <>
      {!hasValidToken && (
        <>
          {showLabel && (
            <p>
              Log into your Google account to start getting your wyshes granted!
            </p>
          )}

          <GoogleLogin onSuccess={onSuccess} onError={onError} useOneTap />
        </>
      )}

      {hasValidToken && (
        <Button
          sx={{ width: "100%" }}
          variant="outlined"
          onClick={() => onLogout()}
        >
          <FontAwesomeIcon
            icon={faRightFromBracket}
            style={{ marginRight: "1em" }}
          />
          Sign Out
        </Button>
      )}
    </>
  );
}

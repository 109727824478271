import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddWishRequest,
  useCreateMemberWishMemberMemberIdWishPostMutation,
} from "../../store/apiSlice";
import { MemberWishForm } from "./MemberWishForm";
import { IMemberWish } from "./interfaces";
import { useAuth } from "../../hooks/useAuth";
import { Alert } from "@mui/material";

export default function AddMemberWish() {
  const { authContext } = useAuth();

  const [
    createWishTrigger,
    { isLoading: isCreating, error, data: createWishResponse },
  ] = useCreateMemberWishMemberMemberIdWishPostMutation();
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (isCompleted || createWishResponse) {
      navigate("/me");
    }
  }, [isCompleted, createWishResponse]);

  useEffect(() => {
    if (error) {
      console.log("An error occurred submitting a wysh.", error);
    }
  }, [error]);

  const onCancel = () => {
    setIsCompleted(true);
  };

  const onSubmit = (data: IMemberWish) => {
    const targetWish = {
      memberId: authContext.userId ?? "",
      addWishRequest: {
        title: data.title ?? "",
        description: data.description ?? "",
        example_url: data.exampleUrl ? data.exampleUrl : undefined,
        properties: data.attributes ? data.attributes : undefined,
      } as AddWishRequest,
    };

    createWishTrigger(targetWish);
  };
  return (
    <>
      {error && (
        <Alert severity={"error"}>
          There was an error submitting your wysh. Please try again.
        </Alert>
      )}
      <MemberWishForm
        submitButtonText="Submit"
        isSubmitting={isCreating}
        onSubmit={(data) => onSubmit(data)}
        onCancel={() => onCancel()}
      />
    </>
  );
}

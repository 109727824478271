import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthContext } from "../../hooks/useAuth";
import { useStorage } from "../../hooks/useStorage";
import { validToken } from "../../routes/login/functions";

const [authContext, setAuthContext] = useStorage("authContext", undefined);

let initialState = {
  firstName: undefined,
  lastName: undefined,
  name: undefined,
  idToken: undefined,
  picUrl: undefined,
  email: undefined,
  userId: undefined,
  exp: undefined,
} as IAuthContext;

if (authContext) {
  if (validToken(authContext)) {
    initialState = authContext;
  } else {
    setAuthContext(undefined);
  }
}
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthContext: (state) => {
      state.firstName = undefined;
      state.lastName = undefined;
      state.name = undefined;
      state.idToken = undefined;
      state.picUrl = undefined;
      state.email = undefined;
      state.userId = undefined;
      state.exp = undefined;
      setAuthContext(null);
    },
    saveAuthContext: (state, action: PayloadAction<IAuthContext>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.idToken = action.payload.idToken;
      state.email = action.payload.email;
      state.picUrl = action.payload.picUrl;
      state.name = action.payload.name;
      state.userId = action.payload.userId;
      state.exp = action.payload.exp;
      state.exp = action.payload.exp;
      setAuthContext(state); //this sets the auth context in storage
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveAuthContext, clearAuthContext } = authSlice.actions;

export default authSlice.reducer;

import { Alert, Box, CircularProgress } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  AcceptInvitationInvitationInviteCodePutApiArg,
  useAcceptInvitationInvitationInviteCodePutMutation,
} from "../../store/apiSlice";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function AcceptInvite() {
  const [inviteCode, setInviteCode] = useState("");
  const [inviteCodeError, setInviteCodeError] = useState(false);
  const [
    acceptInviteTrigger,
    { isLoading, error: acceptInviteError, data: acceptInviteResponse },
  ] = useAcceptInvitationInvitationInviteCodePutMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (acceptInviteResponse && !acceptInviteError) {
      navigate("/me");
    }
  }, [acceptInviteResponse, acceptInviteError]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!inviteCode) {
      setInviteCodeError(true);
      return;
    } else {
      setInviteCodeError(false);
    }

    const target = {
      inviteCode,
    } as AcceptInvitationInvitationInviteCodePutApiArg;

    acceptInviteTrigger(target);
  };
  return (
    <>
      {acceptInviteError && (
        <Box>
          <Alert severity={"error"}>
            There was an issue accepting the invite. Please try again.
          </Alert>
        </Box>
      )}
      <Box>
        <form
          aria-disabled={isLoading}
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e)}
        >
          <Box>
            <Typography paragraph={true}>
              This site is by invitation only and requires an access code to
              sign up.
            </Typography>
            <Typography paragraph={true} gutterBottom={true}>
              Enter the invitation code and accept your invitation to get
              started!
            </Typography>
          </Box>
          <Box>
            <TextField
              id="inviteCode"
              label="Code"
              onChange={(e) => setInviteCode(e.target.value)}
              required
              variant="outlined"
              color="primary"
              type="text"
              sx={{ mb: 3 }}
              value={inviteCode}
              error={inviteCodeError}
              autoFocus={true}
            />
          </Box>
          <Divider sx={{ mb: "1em" }} />
          <Box>
            <Button
              disabled={isLoading}
              variant="outlined"
              color="primary"
              type="submit"
            >
              Accept Invite {isLoading && <CircularProgress size={"1rem"} />}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}

import { ReactNode } from "react";

export const errorTypes = {
  invalidToken: "InvalidToken",
};

export type ErrorType = "InvalidToken" | "Api" | "NotFound";

export interface IErrorResponse {
  message: string;
  description: string | undefined;
  component: ReactNode | undefined;
}

export class BaseError<T extends ErrorType> extends Error {
  public name: T;

  constructor(name: T, message: string) {
    super(message);
    this.name = name;
  }
}

export class InvalidTokenError extends BaseError<ErrorType> {
  constructor() {
    super(
      "InvalidToken",
      "You must sign in to continue using this application.",
    );
  }
}

export class NotFoundError extends BaseError<ErrorType> {
  constructor() {
    super(
      "NotFound",
      "Wherever you go, there you are---and you are in the wrong place.",
    );
  }
}

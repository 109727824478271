import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";
import { validToken } from "../routes/login/functions";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = (props: PropsWithChildren) => {
  const { authContext } = useAuth();
  const previousLocation = useLocation();

  const hasValidToken = validToken(authContext);
  if (!hasValidToken) {
    const loginUrl = `/login?redirectTo=${previousLocation.pathname}`;
    return <Navigate to={loginUrl} />;
  }
  return <>{props.children}</>;
};

import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export type OnAfterClick = () => void;
export interface IMeButtonProps {
  onAfterClick?: OnAfterClick;
}
export default function MeButton({ onAfterClick }: IMeButtonProps) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/me");
    if (onAfterClick) onAfterClick();
  };
  return (
    <>
      <Button
        sx={{ width: "100%", justifyContent: "flex-start" }}
        variant="text"
        onClick={() => onClick()}
      >
        <Typography textAlign="left">
          <FontAwesomeIcon
            icon={faCircleUser}
            color={"#77e6ce"}
            style={{ marginRight: ".5rem" }}
          />
          Me
        </Typography>
      </Button>
    </>
  );
}

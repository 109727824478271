import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function () {
  const { authContext } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    navigate(`/member/${authContext.userId}`, { replace: true });
  }, []);

  return <></>;
}

import React, { FormEvent, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IMemberWish, IMemberWishAttribute } from "./interfaces";
import { mapAttributesToArray } from "./functions";

export type OnSubmitFunc = (data: IMemberWish) => void;
export type OnCancelFunc = () => void;

interface IUserWishFormProps {
  onSubmit: OnSubmitFunc;
  onCancel: OnCancelFunc;
  submitButtonText: string;
  data?: IMemberWish;
  isSubmitting: boolean;
}
export function MemberWishForm({
  data,
  onSubmit,
  onCancel,
  submitButtonText,
  isSubmitting,
}: IUserWishFormProps) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [exampleUrl, setExampleUrl] = useState("");
  const [attributes, setAttributes] = useState([] as IMemberWishAttribute[]);

  useEffect(() => {
    setTitle(data?.title ?? "");
    setDescription(data?.description ?? "");
    setExampleUrl(data?.exampleUrl ?? "");

    const attributes = data ? mapAttributesToArray(data) : [];

    setAttributes(attributes);
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();

    setTitleError(false);
    setDescriptionError(false);

    let hasError = false;
    if (!title) {
      setTitleError(true);
      hasError = true;
    } else {
      setTitleError(false);
    }

    if (!description) {
      setDescriptionError(true);
      hasError = true;
    } else {
      setDescriptionError(false);
    }

    if (!hasError) {
      const properties: Record<string, string> = {};

      for (let i = 0; i < attributes.length; i++) {
        const p: IMemberWishAttribute = attributes[i];
        if (p.value) properties[p.id] = p.value;
      }

      onSubmit({
        id: data?.id,
        ownerId: data?.ownerId,
        title: title,
        description: description,
        exampleUrl: !exampleUrl || exampleUrl == "" ? null : exampleUrl,
        attributes: properties,
      } as IMemberWish);
    }
  };

  const addProperty = () => {
    const key = (attributes.length + 1).toString();
    setAttributes([...attributes, { key, id: "", value: "" }]);
  };

  const setPropertyId = (key: string, id: string) => {
    const property = attributes.find((p) => p.key === key);

    if (property) {
      const filtered = attributes.filter((p) => p.key !== key);

      const sorted = [...filtered, { key, id, value: property.value }].sort(
        (a, b) => (a.key < b.key ? -1 : 1),
      );
      setAttributes(sorted);
    }
  };

  const setPropertyValue = (key: string, value: string) => {
    const property = attributes.find((p) => p.key === key);

    if (property) {
      const filtered = attributes.filter((p) => p.key !== key);
      const sorted = [...filtered, { key, value, id: property.id }].sort(
        (a, b) => (a.key < b.key ? -1 : 1),
      );
      setAttributes(sorted);
    }
  };

  const removeProperty = (key: string) => {
    setAttributes([...attributes.filter((a) => a.key !== key)]);
  };

  return (
    <>
      <form
        aria-disabled={isSubmitting}
        autoComplete="off"
        onSubmit={(e) => handleSubmit(e)}
      >
        <Typography sx={{ mb: "1rem" }} component="h2">
          What is it that you desire?
        </Typography>
        <TextField
          disabled={isSubmitting}
          id="title"
          label="Title your wish"
          onChange={(e) => setTitle(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="text"
          sx={{ mb: "1rem" }}
          fullWidth
          value={title}
          error={titleError}
          autoFocus={true}
        />
        <TextField
          disabled={isSubmitting}
          id="description"
          label="Describe your wish"
          onChange={(e) => setDescription(e.target.value)}
          required
          variant="outlined"
          color="primary"
          type="text"
          value={description}
          error={descriptionError}
          fullWidth
          sx={{ mb: "1rem" }}
        />
        <TextField
          disabled={isSubmitting}
          id="exampleUrl"
          label="A URL example"
          onChange={(e) => setExampleUrl(e.target.value)}
          variant="outlined"
          color="primary"
          type="url"
          value={exampleUrl}
          fullWidth
          sx={{ mb: "1rem" }}
        />
        <Divider sx={{ mb: "1em" }} />
        <Box sx={{ borderRadius: 1, mb: "1rem" }}>
          <Typography sx={{ mb: "1rem" }} component="h2">
            Details i.e. size, fit, color, etc.
          </Typography>
          {attributes.map((property: IMemberWishAttribute) => {
            return (
              <Box
                sx={{
                  border: 1,
                  mb: "1rem",
                  pl: "1.5rem",
                  pt: "1.5rem",
                  borderRadius: 1,
                }}
                key={`box${property.key}`}
              >
                <TextField
                  disabled={isSubmitting}
                  label="Detail Name"
                  onChange={(e) => setPropertyId(property.key, e.target.value)}
                  variant="outlined"
                  color="secondary"
                  type="text"
                  value={property.id}
                  sx={{ mb: ".8rem", mr: ".5em" }}
                />
                <TextField
                  disabled={isSubmitting}
                  label="Detail Value"
                  onChange={(e) =>
                    setPropertyValue(property.key, e.target.value)
                  }
                  variant="outlined"
                  color="secondary"
                  type="text"
                  value={property.value}
                />
                <Button
                  disabled={isSubmitting}
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={() => removeProperty(property.key)}
                  sx={{
                    mb: ".5rem",
                    mt: { xs: ".3rem", sm: ".3rem", md: ".5rem" },
                    ml: { xs: "0px", sm: "0px", md: ".5rem" },
                  }}
                >
                  Remove
                </Button>
              </Box>
            );
          })}
          <Button
            disabled={isSubmitting}
            variant="outlined"
            color="secondary"
            type="button"
            onClick={() => addProperty()}
          >
            Add a Detail
          </Button>
        </Box>
        <Divider sx={{ mb: "1em" }} />
        <Box>
          <Button
            sx={{ mr: "1rem" }}
            disabled={isSubmitting}
            variant="outlined"
            color="primary"
            type="submit"
          >
            {submitButtonText}
            {isSubmitting && <CircularProgress size={"1rem"} />}
          </Button>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            color="secondary"
            type="button"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://ourwyshlist.com/">OurWyshlist&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        mt: { xs: 4, sm: 8 },
        pl: { xs: 4, sm: 8 },
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <div>
        {/*<Link color="text.secondary" href="#">*/}
        {/*  Privacy Policy*/}
        {/*</Link>*/}
        {/*<Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>*/}
        {/*  &nbsp;•&nbsp;*/}
        {/*</Typography>*/}
        {/*<Link color="text.secondary" href="#">*/}
        {/*  Terms of Service*/}
        {/*</Link>*/}
        <Copyright />
      </div>
    </Box>
  );
}

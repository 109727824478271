import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    grantMemberWishMemberMemberIdWishWishIdGrantPost: build.mutation<
      GrantMemberWishMemberMemberIdWishWishIdGrantPostApiResponse,
      GrantMemberWishMemberMemberIdWishWishIdGrantPostApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberId}/wish/${queryArg.wishId}/grant`,
        method: "POST",
      }),
    }),
    ungrantMemberWishMemberMemberIdWishWishIdGrantDelete: build.mutation<
      UngrantMemberWishMemberMemberIdWishWishIdGrantDeleteApiResponse,
      UngrantMemberWishMemberMemberIdWishWishIdGrantDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberId}/wish/${queryArg.wishId}/grant`,
        method: "DELETE",
      }),
    }),
    getMemberWishWishWishIdGet: build.query<
      GetMemberWishWishWishIdGetApiResponse,
      GetMemberWishWishWishIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/wish/${queryArg.wishId}` }),
    }),
    deleteMemberWishMemberMemberIdWishWishIdDelete: build.mutation<
      DeleteMemberWishMemberMemberIdWishWishIdDeleteApiResponse,
      DeleteMemberWishMemberMemberIdWishWishIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberId}/wish/${queryArg.wishId}`,
        method: "DELETE",
      }),
    }),
    updateMemberWishMemberMemberIdWishWishIdPut: build.mutation<
      UpdateMemberWishMemberMemberIdWishWishIdPutApiResponse,
      UpdateMemberWishMemberMemberIdWishWishIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberId}/wish/${queryArg.wishId}`,
        method: "PUT",
        body: queryArg.updateWishRequest,
      }),
    }),
    createMemberWishMemberMemberIdWishPost: build.mutation<
      CreateMemberWishMemberMemberIdWishPostApiResponse,
      CreateMemberWishMemberMemberIdWishPostApiArg
    >({
      query: (queryArg) => ({
        url: `/member/${queryArg.memberId}/wish`,
        method: "POST",
        body: queryArg.addWishRequest,
      }),
    }),
    listMemberWishesMemberMemberIdWishGet: build.query<
      ListMemberWishesMemberMemberIdWishGetApiResponse,
      ListMemberWishesMemberMemberIdWishGetApiArg
    >({
      query: (queryArg) => ({ url: `/member/${queryArg.memberId}/wish` }),
    }),
    getMemberMemberMemberIdGet: build.query<
      GetMemberMemberMemberIdGetApiResponse,
      GetMemberMemberMemberIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/member/${queryArg.memberId}` }),
    }),
    myFamilyMembersFamilyMeMembersGet: build.query<
      MyFamilyMembersFamilyMeMembersGetApiResponse,
      MyFamilyMembersFamilyMeMembersGetApiArg
    >({
      query: () => ({ url: `/family/me/members` }),
    }),
    getFamilyNameDrawFamilyFamilyIdDrawGet: build.query<
      GetFamilyNameDrawFamilyFamilyIdDrawGetApiResponse,
      GetFamilyNameDrawFamilyFamilyIdDrawGetApiArg
    >({
      query: (queryArg) => ({ url: `/family/${queryArg.familyId}/draw` }),
    }),
    drawNamesFamilyFamilyIdDrawPut: build.mutation<
      DrawNamesFamilyFamilyIdDrawPutApiResponse,
      DrawNamesFamilyFamilyIdDrawPutApiArg
    >({
      query: (queryArg) => ({
        url: `/family/${queryArg.familyId}/draw`,
        method: "PUT",
        body: queryArg.createNameDrawRequest,
      }),
    }),
    sendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPut: build.mutation<
      SendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutApiResponse,
      SendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutApiArg
    >({
      query: (queryArg) => ({
        url: `/family/${queryArg.familyId}/member/${queryArg.memberId}/senddraw`,
        method: "PUT",
      }),
    }),
    getMyProfileMeGet: build.query<
      GetMyProfileMeGetApiResponse,
      GetMyProfileMeGetApiArg
    >({
      query: () => ({ url: `/me` }),
    }),
    createInvitationInvitationPost: build.mutation<
      CreateInvitationInvitationPostApiResponse,
      CreateInvitationInvitationPostApiArg
    >({
      query: (queryArg) => ({
        url: `/invitation`,
        method: "POST",
        body: queryArg.inviteRequest,
      }),
    }),
    acceptInvitationInvitationInviteCodePut: build.mutation<
      AcceptInvitationInvitationInviteCodePutApiResponse,
      AcceptInvitationInvitationInviteCodePutApiArg
    >({
      query: (queryArg) => ({
        url: `/invitation/${queryArg.inviteCode}`,
        method: "PUT",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiSlice };
export type GrantMemberWishMemberMemberIdWishWishIdGrantPostApiResponse =
  /** status 200 Successful Response */ Wish;
export type GrantMemberWishMemberMemberIdWishWishIdGrantPostApiArg = {
  memberId: string;
  wishId: string;
};
export type UngrantMemberWishMemberMemberIdWishWishIdGrantDeleteApiResponse =
  /** status 200 Successful Response */ Wish;
export type UngrantMemberWishMemberMemberIdWishWishIdGrantDeleteApiArg = {
  memberId: string;
  wishId: string;
};
export type GetMemberWishWishWishIdGetApiResponse =
  /** status 200 Successful Response */ Wish;
export type GetMemberWishWishWishIdGetApiArg = {
  wishId: string;
};
export type DeleteMemberWishMemberMemberIdWishWishIdDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type DeleteMemberWishMemberMemberIdWishWishIdDeleteApiArg = {
  memberId: string;
  wishId: string;
};
export type UpdateMemberWishMemberMemberIdWishWishIdPutApiResponse =
  /** status 200 Successful Response */ Wish;
export type UpdateMemberWishMemberMemberIdWishWishIdPutApiArg = {
  memberId: string;
  wishId: string;
  updateWishRequest: UpdateWishRequest;
};
export type CreateMemberWishMemberMemberIdWishPostApiResponse =
  /** status 200 Successful Response */ Wish;
export type CreateMemberWishMemberMemberIdWishPostApiArg = {
  memberId: string;
  addWishRequest: AddWishRequest;
};
export type ListMemberWishesMemberMemberIdWishGetApiResponse =
  /** status 200 Successful Response */ ListWishesResponse;
export type ListMemberWishesMemberMemberIdWishGetApiArg = {
  memberId: string;
};
export type GetMemberMemberMemberIdGetApiResponse =
  /** status 200 Successful Response */ FamilyMember;
export type GetMemberMemberMemberIdGetApiArg = {
  memberId: string;
};
export type MyFamilyMembersFamilyMeMembersGetApiResponse =
  /** status 200 Successful Response */ GetFamilyMembersResponse;
export type MyFamilyMembersFamilyMeMembersGetApiArg = void;
export type GetFamilyNameDrawFamilyFamilyIdDrawGetApiResponse =
  /** status 200 Successful Response */ [string, string][];
export type GetFamilyNameDrawFamilyFamilyIdDrawGetApiArg = {
  familyId: string;
};
export type DrawNamesFamilyFamilyIdDrawPutApiResponse =
  /** status 200 Successful Response */ CreateNameDrawResponse;
export type DrawNamesFamilyFamilyIdDrawPutApiArg = {
  familyId: string;
  createNameDrawRequest: CreateNameDrawRequest;
};
export type SendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutApiResponse =
  /** status 204 Successful Response */ void;
export type SendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutApiArg = {
  familyId: string;
  memberId: string;
};
export type GetMyProfileMeGetApiResponse =
  /** status 200 Successful Response */ UserProfile;
export type GetMyProfileMeGetApiArg = void;
export type CreateInvitationInvitationPostApiResponse =
  /** status 200 Successful Response */ InviteResponse;
export type CreateInvitationInvitationPostApiArg = {
  inviteRequest: InviteRequest;
};
export type AcceptInvitationInvitationInviteCodePutApiResponse =
  /** status 200 Successful Response */ UserProfile;
export type AcceptInvitationInvitationInviteCodePutApiArg = {
  inviteCode: string;
};
export type Wish = {
  wish_id?: string | null;
  title?: string | null;
  description?: string | null;
  example_url?: string | null;
  properties?: {
    [key: string]: string;
  } | null;
  granter?: [string, string] | null;
  owner_id?: string | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type UpdateWishRequest = {
  title: string;
  description: string;
  properties?: {
    [key: string]: string;
  } | null;
  example_url?: string | null;
};
export type AddWishRequest = {
  title: string;
  description: string;
  example_url?: string | null;
  properties?: {
    [key: string]: string;
  } | null;
};
export type ListWishesResponse = {
  user_id?: string | null;
  wishes?: Wish[] | null;
};
export type FamilyMember = {
  member_id?: string | null;
  member_name?: string | null;
  pic_url?: string | null;
};
export type GetFamilyMembersResponse = {
  family_id: string;
  family_name: string;
  members?: FamilyMember[] | null;
  is_current_user_admin?: boolean | null;
};
export type CreateNameDrawResponse = {
  result: [string, string][];
};
export type CreateNameDrawRequest = {
  participating_member_ids: string[];
};
export type UserFamily = {
  family_id: string;
  family_name: string;
  is_admin: boolean;
};
export type UserProfile = {
  user_id: string | null;
  user_name: string | null;
  email: string | null;
  email_verified: boolean | null;
  pic_url: string | null;
  parent: string | null;
  families: UserFamily[] | null;
  children: {
    [key: string]: string;
  } | null;
};
export type InviteResponse = {
  name: string;
  email: string;
  invite_code: string;
};
export type InviteRequest = {
  name: string;
  email: string;
  family_id: string;
};
export const {
  useGrantMemberWishMemberMemberIdWishWishIdGrantPostMutation,
  useUngrantMemberWishMemberMemberIdWishWishIdGrantDeleteMutation,
  useGetMemberWishWishWishIdGetQuery,
  useDeleteMemberWishMemberMemberIdWishWishIdDeleteMutation,
  useUpdateMemberWishMemberMemberIdWishWishIdPutMutation,
  useCreateMemberWishMemberMemberIdWishPostMutation,
  useListMemberWishesMemberMemberIdWishGetQuery,
  useGetMemberMemberMemberIdGetQuery,
  useMyFamilyMembersFamilyMeMembersGetQuery,
  useGetFamilyNameDrawFamilyFamilyIdDrawGetQuery,
  useDrawNamesFamilyFamilyIdDrawPutMutation,
  useSendNameDrawEmailFamilyFamilyIdMemberMemberIdSenddrawPutMutation,
  useGetMyProfileMeGetQuery,
  useCreateInvitationInvitationPostMutation,
  useAcceptInvitationInvitationInviteCodePutMutation,
} = injectedRtkApi;

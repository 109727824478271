import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";


export function AcceptInviteButton() {
  const navigate = useNavigate();

  return (
    <Button variant="outlined" onClick={() => navigate("/acceptInvite")}>
          <FontAwesomeIcon
            icon={faSquareCheck}
            style={{ marginRight: "1em" }}
          />
          Accept Invitation
        </Button>
  );
}
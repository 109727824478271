import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { PaletteMode } from "@mui/material";

// A custom theme for this api_app
const createDefaultTheme = (mode?: PaletteMode) =>
  createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: mode,
      primary: {
        main: "#556cd6",
      },
      secondary: {
        main: "#19857b",
      },
      error: {
        main: red.A400,
      },
    },
  });

export default createDefaultTheme;
